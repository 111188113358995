const nav = [
  {
    path: "/user",
    label: "用户管理",
    icon: "UserFilled",
    roles: ['admin'],
    component: () => import("../views/UserMgr/ListView"),
  }, 
  {
    path: "/package_setting",
    label: "套餐设置",
    icon: "Setting",
    roles: ['admin'],
    component: () => import("../views/SettingView/PackageSetting"),
  },
  {
    path: "/price_setting",
    label: "价格设置",
    icon: "Setting",
    roles: ['admin'],
    component: () => import("../views/SettingView/PriceSetting"),
  },
  {
    path: "/report",
    label: "查看订单",
    icon: "Tickets",
    roles: ['admin'],
    component: () => import("../views/ReportView/ListView"),
  }
];

export default nav;
